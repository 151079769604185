import React from "react";

export default () => {
    return (
        <div className="bg-white flex flex-row justify-center items-center w-full h-[100vh]">
            <div className="bg-white border border-solid border-black [background:linear-gradient(180deg,rgb(1,11,120)_0%,rgb(0,0,0)_100%)] w-full h-full flex justify-center items-center">
                <div className="relative w-[922px] h-[657px] rounded-[44.18px] backdrop-blur-[80px] backdrop-brightness-[100%] [-webkit-backdrop-filter:blur(80px)_brightness(100%)] [background:linear-gradient(180deg,rgba(144.5,201.96,255,0.18)_0%,rgba(8.38,97.06,201.16,0.22)_44.17%,rgba(65.35,2.71,243.66,0)_100%)]">
                    <div className="absolute w-[674px] h-[407px] top-32 left-[127px] flex flex-col items-center justify-center">
                        <div className="absolute w-[668px] top-0 left-0 [font-family:'Poppins-Bold',Helvetica] font-bold text-[#ff5e5e] text-5xl text-center tracking-[0] leading-[normal]">
                        This Code is invalid
                                                </div>
                        <div className="flex w-[250px] items-center justify-center gap-2.5 px-20 py-5 absolute top-[325px] left-[210px] bg-[#081dff] rounded-[32px] cursor-pointer">
                        <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-medium text-white text-[27.7px] text-center tracking-[0] leading-[normal]">Try Again                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
};
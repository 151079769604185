import React from 'react';
import './App.css';
import image1 from './1.jpg';
import image2 from './2.jpg';
import image3 from './3.jpg';
import image4 from './4.jpg';

const WhyABIV = () => {
  return (
    <div className="why-abiv-container">
      <div className="content-container">
        <h2 className="title">Why ABIV</h2>
        <p className="description">
ABIV is the one stop platform to study anything from anywhere, all you need is just a pdf or a query to hit and use our ai techniques to help them study.        </p>

        <div className="grid-container">
          <div className="ccard card-left">
            <div className="ccard-content">
              <h3 className="ccard-title">Text to Video</h3>
              <p className="ccard-description">Converts any text to video with enriching user experience and study notes.</p>
            </div>
            <img src={image4} alt="Text to video" className="image" />
          </div>

          <div className="ccard card-right">
            <div className="ccard-content">
              <h3 className="ccard-title">MCQs</h3>
              <p className="ccard-description">Get cured McQs available for you while you watch the animation and make yourself prepared.</p>
            </div>
            <img src={image1} alt="MCQs" className="image" />
          </div>

          <div className="ccard ccard-left">
            <img src={image3} alt="Interview" className="image" />
            <div className="ccard-content">
              <h3 className="ccard-title">DOUBTS</h3>
              <p className="ccard-description">Get Live Doubt Assistance while you study to make your topic enriching.</p>
            </div>
          </div>

          <div className="ccard ccard-right">
            <img src={image2} alt="Doubts" className="image" />
            <div className="ccard-content">
              <h3 className="ccard-title">INTERVIEW</h3>
              <p className="ccard-description">Upcoming Feature to help you cater yourself for Exam Preparation!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyABIV;

import React from 'react';
import { FaQuestionCircle } from "react-icons/fa";
import faqimg from '../assets/Frame 1321314808.png';

const Page8 = () => {
    return (
        <>
            <section className="question py-10 bg-gradient-to-b from-[#000D3F] to-black">
                <div className="questionheading">
                    <h1 className="text-center text-3xl md:text-4xl lg:text-6xl font-bold text-white">Popular Questions</h1>
                </div>
                <div className="questioncard grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 my-10 px-4">
                    {/* Card 1 */}
                    <div className="card1 block p-6 bg-transparent rounded-lg shadow">
                        <div className="flex items-center mb-2">
                            <FaQuestionCircle className='text-blue-500 text-2xl md:text-3xl mr-2' />
                            <h5 className="text-base md:text-lg font-bold tracking-tight text-white">What is ABIV?</h5>
                        </div>
                        <p className="font-normal text-gray-300">ABIV is an AI-powered website developed by RnPsoft that can convert text or PDF documents into animations. It also features exam mode and interview mode.</p>
                    </div>
                    {/* Card 2 */}
                    <div className="card2 block p-6 bg-transparent rounded-lg shadow">
                        <div className="flex items-center mb-2">
                            <FaQuestionCircle className='text-blue-500 text-2xl md:text-3xl mr-2' />
                            <h5 className="text-base md:text-lg font-bold tracking-tight text-white">How does ABIV integrate with other tools?</h5>
                        </div>
                        <p className="font-normal text-gray-300">ABIV seamlessly integrates with various tools such as smart class rooms.</p>
                    </div>
                    {/* Card 3 */}
                    <div className="card3 block p-6 bg-transparent rounded-lg shadow">
                        <div className="flex items-center mb-2">
                            <FaQuestionCircle className='text-blue-500 text-2xl md:text-3xl mr-2' />
                            <h5 className="text-base md:text-lg font-bold tracking-tight text-white">What kind of support does RnPsoft offer for ABIV?</h5>
                        </div>
                        <p className="font-normal text-gray-300">RnPsoft offers 24/7 customer support, including live chat, email support, and a comprehensive knowledge base to help you with any issues.</p>
                    </div>
                    {/* Card 4 */}
                    <div className="card4 block p-6 bg-transparent rounded-lg shadow">
                        <div className="flex items-center mb-2">
                            <FaQuestionCircle className='text-blue-500 text-2xl md:text-3xl mr-2' />
                            <h5 className="text-base md:text-lg font-bold tracking-tight text-white">Can ABIV handle large datasets?</h5>
                        </div>
                        <p className="font-normal text-gray-300">Yes, ABIV is built to handle large datasets efficiently, providing fast and accurate insights regardless of the data volume.</p>
                    </div>
                    {/* Card 5 */}
                    <div className="card5 block p-6 bg-transparent rounded-lg shadow">
                        <div className="flex items-center mb-2">
                            <FaQuestionCircle className='text-blue-500 text-2xl md:text-3xl mr-2' />
                            <h5 className="text-base md:text-lg font-bold tracking-tight text-white">Is ABIV customizable?</h5>
                        </div>
                        <p className="font-normal text-gray-300">Absolutely! ABIV offers a high level of customization to fit the unique needs of your business, including custom dashboards and reports.</p>
                    </div>
                    {/* Card 6 */}
                    <div className="card6 block p-6 bg-transparent rounded-lg shadow">
                        <div className="flex items-center mb-2">
                            <FaQuestionCircle className='text-blue-500 text-2xl md:text-3xl mr-2' />
                            <h5 className="text-base md:text-lg font-bold tracking-tight text-white">What are the pricing plans for ABIV?</h5>
                        </div>
                        <p className="font-normal text-gray-300">ABIV requires credits to work, which can be purchased using UPI, credit, and debit cards. For detailed pricing information, please contact our sales team.</p>
                    </div>
                </div>
                <div className="askquestion mx-auto bg-[#EFEDE0] rounded-lg p-6 text-center max-w-xs md:max-w-md lg:max-w-lg">
                    <h6 className="text-blue-700 font-bold mb-2">Still have questions?</h6>
                    <p className="text-black">Can’t find the answer you're looking for? Please chat with our friendly team.</p>
                    <button type="button" className="bg-blue-700 text-white mt-4 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5" onClick={()=>{
                        window.open('mailto:support@abiv.in', '_blank');
                    }}>Get in Touch</button>
                </div>
            </section>
        </>
    );
}

export default Page8;

import React from 'react'
import Navbar from './Navbar';
import VideoInterview from './VideoInterview';

const Home = () => {
  return (
    <div>
      <Navbar/>
      <VideoInterview/>
    </div>
  )
}

export default Home

import Header from './Header.jsx'
import Page from './Page.jsx'
import './App.css';

function App() {
  return (
    <>
     <div className="body">
    <Header/>
    <Page/>
    </div>
    </>
  );
}

export default App;

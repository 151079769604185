import React from "react";
import "./toc.css"; // Import the CSS file

const RefundPolicyContent = () => (
    <div className="font-normal text-white text-xl leading-relaxed overflow-y-scroll h-3/4 custom-scrollbar">
        <p>
            This refund and cancellation policy outlines how you can cancel or seek a refund for your <strong>Abiv software subscription</strong> purchased through our platform.
        </p>
        <ul className="list-disc list-inside">
            <li>
                <strong>1. Cancellations</strong>
                <ul>
                    <li>Cancellation requests will only be considered if made within <strong>2 days</strong> of subscribing to the software.</li>
                    <li>Cancellations may not be processed if the account has already been activated and the service has been accessed.</li>
                </ul>
            </li>
            <li>
                <strong>2. Non-Refundable Subscriptions</strong>
                <ul>
                    <li>Subscriptions or services marked as <strong>non-refundable</strong> cannot be canceled or refunded under any circumstances.</li>
                    <li>However, if a user demonstrates that the service was defective or failed to function as advertised, a refund may be considered.</li>
                </ul>
            </li>
            <li>
                <strong>3. Defective Service</strong>
                <ul>
                    <li>If you encounter any technical issues or defects with the software, you must report them to our <strong>customer service team</strong> within <strong>2 days</strong> of subscribing or encountering the issue.</li>
                    <li>Our technical team will investigate the claim, and a refund or resolution may be offered if the issue is validated.</li>
                </ul>
            </li>
            <li>
                <strong>4. Unsatisfactory Service Experience</strong>
                <ul>
                    <li>If the service does not meet your expectations or as described, you must bring it to the notice of <strong>customer service</strong> within <strong>2 days</strong> of encountering the issue.</li>
                    <li>After reviewing your concern, the team will take appropriate action, which may include adjustments to your subscription or a refund in rare cases.</li>
                </ul>
            </li>
            <li>
                <strong>5. Refund Processing</strong>
                <ul>
                    <li>Approved refunds will be processed by <strong>RNPSOFT PRIVATE LIMITED</strong> within <strong>7 days</strong>, and the amount will be credited to the original payment method.</li>
                </ul>
            </li>
            <li>
                <strong>6. Third-Party Integrations</strong>
                <ul>
                    <li>If Abiv integrates with third-party services or tools, any issues related to those services should be addressed directly with their respective providers as per their policies.</li>
                </ul>
            </li>
        </ul>
    </div>
);

const RefundPolicy = () => {
    return (
        <div className="bg-white flex justify-center items-center min-h-screen">
            <div className="bg-gradient-to-b from-blue-900 to-black w-full h-screen flex justify-center items-center">
                <div className="relative w-4/5 h-4/5 bg-blue-800 rounded-2xl border-4 border-white shadow-lg p-10">
                    <h1 className="font-semibold text-white text-5xl text-center mb-10">
                        Refund Policy
                    </h1>
                    <RefundPolicyContent />
                </div>
            </div>
        </div>
    );
};

export default RefundPolicy;

import React from "react";
import loginpng from './for-login.png';
import Header from './Header';
export default function ContactPage() {
    return (
        <div>
            <Header />
        <div className="bg-white flex flex-col items-center w-full min-h-screen">
            <div className="bg-gradient-to-b from-blue-800 to-blue-900 w-full flex justify-center py-12">
                <div className="relative w-full max-w-4xl bg-cover bg-center" style={{ backgroundImage: loginpng, height: '100vh' }}>
                    <div className="absolute inset-0 flex flex-col items-center justify-center text-white text-center bg-black bg-opacity-50 p-8 rounded-lg">
                        <h1 className="text-4xl md:text-6xl font-bold mb-8">Contact Us</h1>
                        <form className="w-full max-w-lg space-y-6 bg-white bg-opacity-90 p-8 rounded-lg shadow-lg">
                            <div className="flex flex-col md:flex-row md:space-x-4">
                                <div className="flex-1">
                                    <label className="block text-sm font-medium mb-2 text-gray-700">First Name</label>
                                    <input type="text" placeholder="Enter your first name" className="w-full px-4 py-2 rounded-lg bg-gray-100 text-black border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500" />
                                </div>
                                <div className="flex-1 mt-4 md:mt-0">
                                    <label className="block text-sm font-medium mb-2 text-gray-700">Last Name</label>
                                    <input type="text" placeholder="Enter your last name" className="w-full px-4 py-2 rounded-lg bg-gray-100 text-black border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500" />
                                </div>
                            </div>
                            <div>
                                <label className="block text-sm font-medium mb-2 text-gray-700">Email</label>
                                <input type="email" placeholder="Enter your email" className="w-full px-4 py-2 rounded-lg bg-gray-100 text-black border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500" />
                            </div>
                            <div>
                                <label className="block text-sm font-medium mb-2 text-gray-700">Phone Number</label>
                                <input type="tel" placeholder="Enter your phone number" className="w-full px-4 py-2 rounded-lg bg-gray-100 text-black border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500" />
                            </div>
                            <div>
                                <label className="block text-sm font-medium mb-2 text-gray-700">Message</label>
                                <textarea placeholder="Enter your message" className="w-full px-4 py-2 rounded-lg bg-gray-100 text-black border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 h-32"></textarea>
                            </div>
                            <div className="flex justify-center">
                                <button type="submit" className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

import React ,{useState}from "react";

export default () => {
    const [code, setCode] = useState("");
    return (
        <div className="bg-white flex flex-row justify-center items-center w-full h-[100vh]">
            <div className="bg-white border border-solid border-black [background:linear-gradient(180deg,rgb(1,11,120)_0%,rgb(0,0,0)_100%)] w-full h-full flex justify-center items-center">
                <div className="relative w-[922px] h-[657px] rounded-[44.18px] backdrop-blur-[80px] backdrop-brightness-[100%] [-webkit-backdrop-filter:blur(80px)_brightness(100%)] [background:linear-gradient(180deg,rgba(144.5,201.96,255,0.18)_0%,rgba(8.38,97.06,201.16,0.22)_44.17%,rgba(65.35,2.71,243.66,0)_100%)]">
                    <div className="absolute w-[674px] h-[407px] top-32 left-[127px] flex flex-col items-center justify-center">
                        <div className="absolute w-[668px] top-0 left-0 [font-family:'Poppins-Bold',Helvetica] font-bold text-[#fffdfd] text-5xl text-center tracking-[0] leading-[normal]">
                            Redeem Your Credit Coins!
                        </div>
                        <p className="absolute w-[593px] top-[119px] left-[38px] [font-family:'Poppins-Medium',Helvetica] font-medium text-[#fffdfd] text-[27.4px] text-center tracking-[0] leading-[normal]">
                            Enter The 6-digit Code From Your Card.
                        </p>
                        <input
                            type="text"
                            className="absolute w-[665px] h-[72px] top-[188px] left-[3px] bg-[#b6b4b44c] rounded-[44.18px] border border-solid border-white text-center text-white text-[27.7px] [font-family:'Poppins-Medium',Helvetica] font-medium"
                            placeholder="Enter Code"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                        />
                        <div className="flex w-[250px] items-center justify-center gap-2.5 px-20 py-5 absolute top-[325px] left-[210px] bg-[#081dff] rounded-[32px] cursor-pointer" onClick={()=>{
                            fetch('https://abiv.rnpsoft.com/coupons', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({ couponCode: code })
                            })
                            .then(response => response.json())
                            .then(data => {
                                if(data.message==1){
                                    const x = JSON.parse(localStorage.getItem('user-data'));
                                    fetch('https://abiv.rnpsoft.com/addcoins', {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({ email: x.email, coins: 100 })
                                    })
                                    .then(response => response.json())
                                    .then(data => {
                                        console.log(data);
                                    }
                                    ).catch((error) => {
                                        console.error('Error:', error);
                                    });
                                    window.location.href='/redeemsuccess';
                                }
                                else{
                                    window.location.href='/redeemfail';
                                }
                            })
                            .catch((error) => {
                                console.error('Error:', error);
                            });
                        }}>
                            <div className="relative w-fit [font-family:'Poppins-Medium',Helvetica] font-medium text-white text-[27.7px] text-center tracking-[0] leading-[normal]">
                                Redeem Now
                            </div>
                        </div>
                    </div>
                    <p className="absolute w-[457px] top-[582px] left-[233px] [font-family:'Poppins-Medium',Helvetica] font-medium text-[#d9d9d9] text-[27.7px] text-center tracking-[0] leading-[normal] underline cursor-pointer">
                        No Coupon? Buy Credits Online.
                    </p>
                </div>
            </div>
        </div>
    );
};
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import FadeLoader from 'react-spinners/FadeLoader';
import axios from 'axios';
import Modal from 'react-modal';

import Login from './LoginSignup2/LoginSignup2'
import Signin from './LoginSignup2/SignUp'
const Popup = ({filedata}) => {
  const [closer, setCloser] = useState(true);
    const [loader, setLoader] = useState(false);
    const nav = useNavigate();
    const loadingHandler = () => setLoader(true);
    const [language,setLanguage] =useState("")
    const [lngSelected,setLngSelected]=useState(false);
    const [flagged,setflag]=useState(0)
    const random=generateRandomCode();
    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const [login,setsignin]=useState(false)
        const [signin,signup]=useState(false)
    let file=filedata.state.file
    file=file[0]
    function generateRandomCode(length = 6) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      
      return result;
  }
    const handleUpload = async (data) => {
      
      console.log(file)
      if (!file) {
        console.error('No file selected');
        return;
      }
    
      try {
        console.log('Uploading file:', file.name);
        if (language.includes('False'))data='False'
        else if(language.includes('True1'))data='True1'
        else data='True'
       
        const formData = new FormData();
        formData.append('file', file);
    formData.append('text',data);
    formData.append('email',JSON.parse(localStorage.getItem('user-data')).email)
    formData.append('sessionid',random)
    localStorage.setItem('sessionid',random)
        const response = await axios.post('https://abiv.rnpsoft.com/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        if (response.status === 200) {
          const result = response.data; // Axios already parses the response data
          console.log('Server response:', result);
          alert('File uploaded successfully: ' + result);
          localStorage.setItem('flagged',result);
        } 
    
        console.log('File uploaded successfully', response);
        const x = JSON.parse(localStorage.getItem('user-data'));
        
        console.log(x);
        fetch('https://abiv.rnpsoft.com/fetchdata', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: x.email })
        })
        .then(response => response.json())
        .then(data => {
let c=data.data;
if(!c.videos)c.videos={};
c.videos[random]={
  'title':file.name,
  'sessionid':random,
  'language':language,
  'creationdate':new Date().toLocaleDateString()
}
          fetch("https://abiv.rnpsoft.com/updatedb", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(c)
          })
        })
        
      } catch (error) {
        console.error('Error uploading file', error);
      }
    };
    const handelLanguage=(e)=>{
      setLanguage(e);
      setLngSelected(true)
      console.log(language)
      loadingHandler()
    }

    console.log(language)

    useEffect(() => {
      localStorage.setItem('capt','0');
      localStorage.setItem('capt1','0');
      localStorage.setItem('duration','0');
      localStorage.setItem('appeared','0');
      
      localStorage.setItem('b', JSON.stringify(0));
      localStorage.setItem('animation', null);
      localStorage.setItem('teacher', null);
        const closeHandler = () => {
            if (closer) {
              setCloser(false);
              console.log('I am triggered')
            }
            else if (loader) {
                setLoader(false);
                handleUpload("True")
                localStorage.setItem('capt','0');
      localStorage.setItem('duration','0');
      localStorage.setItem('appeared','0')
      
      localStorage.setItem('b', JSON.stringify(0));
      localStorage.setItem('animation', null);
      localStorage.setItem('teacher', null);
      if(language.includes('True1'))setLanguage('True')
      localStorage.setItem('language',language)
      localStorage.setItem('isTheory','False');
      localStorage.setItem('isDoubt','False');
localStorage.setItem('isCheck','True')
setTimeout(() => {
                nav("/video", { state: { flagged,lngSelected },sessionid:{random}});  
}, 3000); 
            }
        }
        
        setTimeout(() => closeHandler(), 3000)
    }, [closer, loader, nav]);
  return (
    <div className='z-[2] w-full h-[95%] md:w-[90%] md:h-[85%] flex justify-center items-center flex-col'>
      <h1 className={`sm:text-4xl lg:text-[2.5rem] text-[1.75rem] w-full font-bold ${closer && 'text-[#928d8d]'} text-center`}>Study with any Text/Image/PDF</h1>
      <p className={`text-[#B9B9B9] font-bold text-sm sm-text-lg md:text-xl p-3 w-[90%] lg:w-[60%] text-center ${closer && 'hidden'}`}>Join millions of students, researchers and professionals to instantly answer questions and understand research with AI</p>

      {closer 
        ? <div className='m-2 h-[35%] w-[80%] sm:h-[65%] sm:w-[60%] lg:w-[50%] border text-white flex justify-center items-center border-[#D9D9D9] bg-[#ffff] mt-10 max-h-80'><FadeLoader color="#adadad" /></div>
        : <div className='m-2 h-[35%] w-[80%] sm:h-[65%] sm:w-[60%] lg:w-[50%] border-[2px] border-dashed rounded-lg text-white flex flex-col justify-evenly items-center text-center border-[#D9D9D9] bg-[#010C16] mt-10 max-h-80'>
          
          <br /><br />  <p className={`text-sm text-[#828282] md:text-[16px] tracking-tight ${lngSelected && 'hidden'}`}>Please choose the language you are comfortable with!</p>
            <br /><br /> <br /><br /> <br /><br />
            <div className={`flex justify-evenly w-full ${lngSelected && 'hidden'}`}>
             
              <button className='tracking-tight text-center w-[7rem] p-2 h-[2rem] text-[10px] bg-[#8d53df] rounded-3xl' defaultValue={"English"} onClick={() => { 
                              if(!localStorage.getItem('auth-token')){
                                setIsModalOpen1(true)
                                setsignin(true);
                              }else{
                                fetch('https://abiv.rnpsoft.com/fetchdata', {
                                  method: 'POST',
                                  headers: {
                                      'Content-Type': 'application/json'
                                  },
                                  body: JSON.stringify({email: JSON.parse(localStorage.getItem('user-data')).email})
                                }).then(response => response.json())
                                .then(data=>{
                                  if(data.data.coins>=20){
                                    handelLanguage('False')
                                  }
                                  else if(data.data.coins<20&&data.data.coins>=4){
                                    alert('You have less than 20 coins, please recharge your wallet')
                                    handelLanguage('False')
                                  }
                                  else{
                                    alert('You have less than 4 coins, please recharge your wallet to continue')
                                  }
                                })
                              } }}>Continue in English</button>
              <button className='tracking-tight w-[7rem] text-center p-2 h-[2rem] text-[10px] bg-[#8d53df] rounded-3xl' defaultValue={"Hindi"} onClick={() => { 
                              if(!localStorage.getItem('auth-token')){
                                setIsModalOpen1(true)
                                setsignin(true);
                              }else{
                                fetch('https://abiv.rnpsoft.com/fetchdata', {
                                  method: 'POST',
                                  headers: {
                                      'Content-Type': 'application/json'
                                  },
                                  body: JSON.stringify({ email: JSON.parse(localStorage.getItem('user-data')).email })

                                }).then(response => response.json())
                                .then(data=>{
                                  if(data.data.coins>=20){
                                    handelLanguage('True')
                                  }
                                  else if(data.data.coins<20&&data.data.coins>=4){
                                    alert('You have less than 20 coins, please recharge your wallet')
                                    handelLanguage('True')
                                  }
                                  else{
                                    alert('You have less than 4 coins, please recharge your wallet to continue')
                                  }
                                })
                                }}}>Continue in Hindi</button>
              <button className='tracking-tight w-[7rem] text-center p-2 h-[2rem] text-[10px] bg-[#8d53df] rounded-3xl' defaultValue={"Hindi"} onClick={() => { 
                              if(!localStorage.getItem('auth-token')){
                                setIsModalOpen1(true)
                                setsignin(true);
                              }else{
                                fetch('https://abiv.rnpsoft.com/fetchdata', {
                                  method: 'POST',
                                  headers: {
                                      'Content-Type': 'application/json'
                                  },
                                  body: JSON.stringify({ email: JSON.parse(localStorage.getItem('user-data')).email })

                                }).then(response => response.json())
                                .then(data=>{
                                  if(data.data.coins>=20){
                                    handelLanguage('True1')
                                  }
                                  else if(data.data.coins<20&&data.data.coins>=4){
                                    alert('You have less than 20 coins, please recharge your wallet')
                                    handelLanguage('True1')
                                  }
                                  else{
                                    alert('You have less than 4 coins, please recharge your wallet to continue')
                                  }
                                }) }}}>Continue in Hinglish</button>
            </div>
            <br /><br /> <br /><br /> <br /><br />
            {lngSelected && <FadeLoader color="#adadad" />}
          </div>
      }
      <Modal
                isOpen={isModalOpen1}
                onRequestClose={()=>{
                    setIsModalOpen1(false)
                }}
                contentLabel="Record Case Proceeding"
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '400px',
                        padding: '20px',
                        borderRadius: '10px',
                        boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
                        zIndex: '60'
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.75)',
                        zIndex: '59'
                    }
                }}
            >
               {login && !signin && (
<Login islogin={setsignin} issignup={signup} setmodel={setIsModalOpen1}></Login> 
            )            
            }
            {signin && (
<Signin setsign={signup} setmodel={setIsModalOpen1}/> 

                )
            }
            </Modal>
    </div>
  )
}

export default Popup

import React from 'react'
import Header from '../components/Header';
import Pricing from '../components/Pricing/Pricing';

function PricingPage() {
  return (
    <div>
        <Header/>
        <Pricing />

      
    </div>
  )
}

export default PricingPage

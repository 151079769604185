import React,{useState,useEffect} from 'react'

export default function Header() {
  const [name,setName]=useState('')
  useEffect(()=>{
    fetch('https://abiv.rnpsoft.com/fetchdata',{
      method:'POST',
      headers:{
        'Content-Type':'application/json'
      },
      body:JSON.stringify({
        email:JSON.parse(localStorage.getItem('user-data')).email
      })
     
    }).then((res)=>res.json()).then((data)=>{console.log(data.data);setName(data.data.firstName+' '+data.data.lastName)})
  },[])
  return (
    <>
      <div className="main-header flex flex-col md:flex-row justify-between items-start md:items-center p-4">

        <span className="text-left mb-4 md:mb-0">
          <h1 className="text-2xl md:text-4xl font-bold mb-2 md:mb-4">All The Best!</h1>
          <p className="text-lg md:text-2xl font-semibold">
            Answer all questions, and submit
            <br />
            your answers in preferable way
          </p>
        </span>

        <span className="text-lg md:text-xl ml-0 md:ml-4">
          <p className='text-left'><b>Name:</b> {name
          }</p>
          <br></br>
          <p className='text-left'><b>Subject:</b> {localStorage.getItem('ExamName')}</p>
        </span>

      </div>
    </>
  )
}

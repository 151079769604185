import React from 'react'
import Header from '../components/Header'
import Doubt from '../components/Doubt'

const DoubtPage = () => {
  return (
    <>
        <Header/>
        <Doubt />
    </>
  )
}

export default DoubtPage
